import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './Home'
import Contact from './Contact'
import './App.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function App() {
  return (
    <Router>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossOrigin="anonymous"
      />
      <div className='no-gutters'>
        <nav>
        </nav>
        <Switch>
          <Route path='/contact'>
            <Contact header="Contact Us"
              content="Feel free to shoot us an email with any questions, requests, or feedback that you may have for TriggerTime. Please send your message to the email below."
              email="contact@triggertime.co"
             />
          </Route>
          <Route path='/partners'>
            <Contact header="Partners"
              content="We at TriggerTime greatly value partnerships and collaboration. Our strategy relies on methodical selection of people to develop and deliver exceptional value to our customers. We seek to establish new business partnerships to shape the future of our industry. Please contact us to further discuss partnership opportunites."
              email="partners@triggertime.co"
            />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
        <footer className='footer'>
          <Container fluid='true'>
            <Row className="justify-content-center">
              <Col xs={2} sm={2}>
                <a href="https://www.facebook.com/triggertime.co" className="fa fa-facebook"/>
              </Col>
              <Col xs={2} sm={2}>
                <a href="https://www.instagram.com/triggertime.co/" className="fa fa-instagram"/>
              </Col>
            </Row>
            <Row className="justify-content-center footer-row">
              <Col xs={12} sm={3}>
                <button className="btn btn-link vrlps-trigger">Get Early Access</button>
              </Col>
              <Col xs={12} sm={3}>
                <a href="/" className="btn btn-link">Home</a>
              </Col>
              <Col xs={12} sm={3}>
                <a href="/contact" className="btn btn-link">Contact</a>
              </Col>
              <Col xs={12} sm={3}>
                <a href="/partners" className="btn btn-link">Partners</a>
              </Col>
            </Row>
            <Row className="justify-content-center footer-row">
              <Col>
                <p className='text-secondary cta'>
                  Copyright © TriggerTime {new Date().getFullYear()}. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </Router>
  );
}

export default App;
