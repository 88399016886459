import * as React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Affix } from 'antd';
import { LineChart, Line, ResponsiveContainer, CartesianGrid, YAxis } from 'recharts';


class Home extends React.Component {
  render() {
    return (
      <div>
        {/* Landing */}
        <Container fluid='true' >
          <Row className='justify-content-center align-items-end' id='landing-bg'>
            <Col xs={12} sm={12} md={8} lg={9} xl={5} id='landing-content'>
              <h1 className="cta">TriggerTime</h1>
              <h2 className="cta">Your time at the range just got a whole lot better</h2>
              <p id="landing-p" className="cta secondary-text">Crush your goals, collect stats, understand every shot, and track biometrics while shooting at the range.</p>
              <Affix offsetTop={20} className='sticky'>
                <Button type="button" className="vrlps-trigger deep-shadow" block size="lg">Get Early Access</Button>
              </Affix>
            </Col>
          </Row>
        </Container>

        {/* Shot Goal Ring; Complications */}
        <Container fluid='true'>
          <Row className="justify-content-center align-items-center">
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 6, order: 2 }} xl={{ span: 6, order: 2 }}>
              <img className='image' alt='Apple Watch and iPhone displaying Shot Goal Ring'
                src={require('./shotGoal.jpg')} />
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 5, order: 1 }} xl={{ span: 4, order: 1 }}>
              <h1>One ring. One goal.</h1>
              <p>Becoming a better shot starts with setting a goal. This goal is to get you to the range to get some time behind the trigger. You set how many shots you want to take each month, and your mission is to close the Shot Goal Ring every month. Simple, right? But it gets even better.</p>
            </Col>
          </Row>


          {/* Shooting is Exercise; Stats; Overview */}
          <Row className="justify-content-center align-items-center">
            <Col xs={{ span: 6, order: 1 }} sm={{ span: 6, order: 3 }} md={{ span: 4, order: 3 }} lg={{ span: 4, order: 3 }} xl={4}>
              <img className='image' alt='Apple Watches displaying a Shot Goal Notification and Complication'
                src={require('./notificationComplication.png')} />
            </Col>
            <Col xs={{ span: 12, order: 3 }} sm={{ span: 6, order: 2 }} md={{ span: 5, order: 2 }} lg={{ span: 4, order: 2 }} xl={4}>
              <h1>Matrix level metrics.</h1>
              <p>Whether you are a recreational shooter, skills builder, or professional, TriggerTime helps you shoot smarter. While doing shooting exercises, TriggerTime keeps track of several things like the number of shots taken and biometrics such as heartrate and active calories. Additionally, each shot and its motion data gets recorded so you can visualize each and every single shot you take.</p>
              <p>TriggerTime sends you motivational reminders to close your Shot Goal Ring. You can also pick from a ton of watch face complications to keep track of your goal at a glance of the wrist.</p>
            </Col>
            <Col xs={{ span: 6, order: 2 }} sm={{ span: 6, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 4, order: 1 }} xl={3}>
              <img className='image' alt='iPhone displaying Shooting Exercise Stats'
                src={require('./statsScreen.jpg')} />
            </Col>
          </Row>


          {/* It just works; No settings, works with any gun */}
          <Row className='align-items-end' id='rifle'>
            <Col id='pistol' sm={3} md={{ span: 4, order: 1 }} lg={5} xl={4}>
            </Col>
            <Col sm={12} md={{ span: 6, order: 2 }} lg={5} xl={5}>
              <h1>You bring the hardware. We'll bring the software.</h1>
              <p>You are free to use whatever firearm you want while shooting with TriggerTime. It doesn't matter if you're shooting a pistol, a rifle, or shotgun – it just works. You don't need to calibrate anything or mount other attachments. Just start an exercise and you're ready to rock. We cannot promise optimal performance while shooting a minigun, however.</p>
            </Col>
            <Col id='shotgun' sm={12} md={{ span: 12, order: 3 }} lg={12} xl={12}>
            </Col>
          </Row>

          {/* Compete with friends */}
          <Row id='compete' className="justify-content-center align-items-center">
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 5, order: 1 }} xl={{ span: 4, order: 1 }}>
              <img className='image' alt='iPhone displaying Shot Goal Ring Sharing'
                src={require('./sharing.png')} />
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 5, order: 1 }} xl={{ span: 4, order: 1 }}>
              <h1>Fire up some friendly competition.</h1>
              <p>In addition to sharing your Shot Goal Ring, you can challenge your friends to a month long competition. The first person to close their Shot Goal Ring wins. While you compete, feel free to give your friends a bit of motivation with some smack talk.</p>
            </Col>
          </Row>

          {/* THE BNE - Ballistic Neural Engine */}
          <Row id='BNE' className="justify-content-center align-items-center">
            <Col xs={12}>
              <Row className="justify-content-center align-items-center">
                <div className="graph-info">
                  <Col xs={12} sm={12} md={8} lg={9} xl={5}>
                    <h1 className='cta'>Powered by the Ballistic Neural Engine</h1>
                    <p className='shadow'>TriggerTime analyzes over 11,000 motion metrics per second during shooting exercises. Given this data, TriggerTime can guide you on how to become a better shot. Machine Learning technology drives the Ballistic Neural Engine to improve your shooting in real-time. This stuff is straight out of science fiction.</p>
                    <img className='image' alt='iPhone displaying Current Exercise Tips powered by Ballistic Neural Engine'
                      src={require('./bne.png')} />
                  </Col>
                </div>
                <ResponsiveContainer minHeight={window.innerHeight * .75} width='100%'>
                  <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <CartesianGrid vertical={false} strokeOpacity={0.5} />
                    <YAxis tickCount={6} />
                    <Line type="natural"
                      strokeWidth={3}
                      dataKey="x"
                      stroke="#fc473b"
                      dot={false} activeDot={false}
                    />
                    <Line type="natural"
                      strokeWidth={3}
                      dataKey="y"
                      stroke="#28dc5b"
                      dot={false} activeDot={false}
                    />
                    <Line type="natural"
                      strokeWidth={3}
                      dataKey="z"
                      stroke="#0688ff"
                      dot={false} activeDot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Row>
            </Col>
          </Row>

          {/* Get Early Access */}
          <Row className="justify-content-center align-items-center">
            <Col xs={12} sm={12} md={8} lg={9} xl={5}>
              <h1>Get invloved with early access.</h1>
              <p>By signing up for early access, you can be involved with the TriggerTime community and peer behind scenes with the development of TriggerTime. You will receive monthly developer updates and early access to the TriggerTime app before public release. We look forward to working with you.</p>
              <Button type="button" className="vrlps-trigger" block size="lg">Get Early Access</Button>
            </Col>
          </Row>

        </Container>
      </div>
    )
  }
}

const data = [
  {
    "name": "0",
    "x": -0.01231849193572998,
    "y": -0.00977480411529541,
    "z": -0.021083414554595947
  },
  {
    "name": "1",
    "x": -0.01231849193572998,
    "y": -0.04988330602645874,
    "z": -0.013157486915588379
  },
  {
    "name": "2",
    "x": -0.01907607913017273,
    "y": -0.0884474515914917,
    "z": -0.03647565841674805

  },
  {
    "name": "3",
    "x": -0.05070224404335022,
    "y": -0.09284985065460205,
    "z": -0.014034390449523926

  },
  {
    "name": "4",
    "x": 0.05881652235984802,
    "y": -0.12033993005752563,
    "z": -0.030034661293029785

  },
  {
    "name": "5",
    "x": -0.09225323796272278,
    "y": -0.12534606456756592,
    "z": -0.02103865146636963

  },
  {
    "name": "6",
    "x": -0.13403835892677307,
    "y": -0.17928177118301392,
    "z": 0.02053046226501465

  },
  {
    "name": "7",
    "x": -0.13403835892677307,
    "y": -0.17928177118301392,
    "z": 0.027358412742614746

  },
  {
    "name": "8",
    "x": -0.12886738777160645,
    "y": -0.02303183078765869,
    "z": 0.05617654323577881

  },
  {
    "name": "9",
    "x": -0.11828944087028503,
    "y": -0.1604885458946228,
    "z": 0.16835904121398926

  },
  {
    "name": "10",
    "x": -0.14590442180633545,
    "y": -0.017813503742218018,
    "z": 0.24109363555908203

  },
  {
    "name": "11",
    "x": -0.22643941640853882,
    "y": 0.03470921516418457,
    "z": 0.23122179508209229

  },
  {
    "name": "12",
    "x": -0.21666789054870605,
    "y": 2.745556354522705,
    "z": 0.6634533405303955

  },
  {
    "name": "13",
    "x": -0.0588478147983551,
    "y": 1.0738682746887207,
    "z": 0.018468379974365234

  },
  {
    "name": "14",
    "x": 1.9985473155975342,
    "y": -1.6842668056488037,
    "z": -1.4665454626083374

  },
  {
    "name": "15",
    "x": -0.7533309459686279,
    "y": -0.7944297790527344,
    "z": -0.7046716213226318

  },
  {
    "name": "16",
    "x": 0.4968376159667969,
    "y": -0.04629170894622803,
    "z": -0.13778269290924072

  },
  {
    "name": "17",
    "x": 0.3729354739189148,
    "y": -0.07526850700378418,
    "z": 0.586661696434021

  },
  {
    "name": "18",
    "x": -0.23813873529434204,
    "y": 0.37433695793151855,
    "z": 0.23359525203704834

  },
  {
    "name": "19",
    "x": -0.3706686198711395,
    "y": 0.2802048921585083,
    "z": 0.3363138437271118

  },
  {
    "name": "20",
    "x": -0.17290303111076355,
    "y": 0.11134541034698486,
    "z": 0.24329841136932373

  },
  {
    "name": "21",
    "x": -0.05206751823425293,
    "y": 0.04236340522766113,
    "z": 0.1180262565612793

  },
  {
    "name": "22",
    "x": -0.13316798210144043,
    "y": -0.05461406707763672,
    "z": -0.08698225021362305

  },
  {
    "name": "23",
    "x": -0.07388573884963989,
    "y": -0.04801231622695923,
    "z": -0.13987678289413452

  },
  {
    "name": "24",
    "x": 0.027174323797225952,
    "y": 0.03404480218887329,
    "z": -0.09901154041290283

  },
]

export default Home