import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
function Contact(props) {
  return (
    <Container>
      <Row className='justify-content-center'>
        <img alt='Logo' width={150} height={150} src={require('./logos.png')} />
      </Row>
      <Row className='justify-content-center'>
        <h1>{props.header}</h1>
      </Row>
      <h2>{props.content}</h2>
      <h3><a href={"mailto:" + props.email}>{props.email}</a></h3>
    </Container>
  )

}

export default Contact
